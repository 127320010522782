
    .btn-action{
        border-radius: 100px;
    }
    .template{
        width: 100%;
        height: 100%;
        overflow-wrap: break-word;
        &.card-template{
            background-color: #FFFFFF;
            border-radius: 20px;
            padding: 16px;
        }
        &.landing-page .content{
            min-height: 100vh;
            display: flex;
            flex-direction: column;
        }

        .img-background{
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
        .footer{
            max-width: 100%;
            .footer_html{

                word-break: break-all;

                :deep()li{
                    text-align: start!important;
                }
                :deep()p{
                    font-size: inherit !important;
                }
            }
        }

        .start-campaign-button{
            min-width: fit-content;
            overflow: hidden;
        }
    }
    :deep().ql-size-small{
        font-size: 0.75em!important;
    }
    :deep().ql-size-large{
        font-size: 1.5em!important;
    }
    :deep().ql-size-huge{
        font-size: 2.5em!important;
    }
